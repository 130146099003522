import { Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";


export const DropDownMenu = ({menuItems, placement, type, setType}) => {

    const menu = (
      <Menu>
          {menuItems.map((item,ind) => <Menu.Item key={ind} onClick={()=> setType(item)}>
          <span>{item}</span>
        </Menu.Item>)}
      </Menu>
    );

    
  return (
    <Dropdown overlay={menu} trigger={["click"]} placement={placement}>
      <p className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
        {type} <DownOutlined />
      </p>
    </Dropdown>
  );
};
