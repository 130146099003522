import { createSlice } from '@reduxjs/toolkit';
import { EditorState } from 'draft-js';
import { v4 } from 'uuid';

const initialState = {
    activeEditorInstance: "editorInstanceOne",
    editorInstancesIds: ["editorInstanceOne"],
    editorInstancesDelta: [],
    editorInstances: {},
    docTitle: "Untitled Pad",
    editorRef: null,
    editorState: EditorState.createEmpty(),
    documentInformation: {},
    isReadOnly: false,
    userInformation: {},
    mouse: [],
    isExport: '',
    isFileChoose: false,
    file: {},
    pages: [{
        pageId: `page-${v4()}`,
        editorState: EditorState.createEmpty(),
        isActive: true,
    }],
    signatures: [],
    savingData: {
        isSaving: "",
        lastSavedDateTime: "",
        fileSize:0
    }
}

const editorSlice = createSlice({
    name: 'editor',
    initialState,
    reducers: {
        pushEditorInstanceId: (state, actions) => {
            const { editorInstanceId } = actions.payload;
            state.editorInstancesIds.push(editorInstanceId);
            state.activeEditorInstance = editorInstanceId;
        },
        setDocumentInformation(state, action) {
            state.documentInformation = action.payload;
            const searchParams = new URLSearchParams(document.location.search)
            const isReadOnlyQuery = searchParams.get("readOnly")
            if (isReadOnlyQuery == "true" || action.payload.rightType == 1) {
                state.isReadOnly = true;
            }
        },
        setUserInformation(state, action) {
            state.userInformation = action.payload;
        },
        setMouse(state, action) {
            state.mouse = action.payload;
        },
        insertEditorInstancesDelta: (state, action) => {
            state.editorInstancesDelta = action.payload
        },
        updateActiveEditor: (state, actions) => {
            const { activeEditorInstance } = actions.payload;
            state.activeEditorInstance = activeEditorInstance;
        },
        setEditorRef: (state, action) => {
            state.editorRef = action.payload;
        },
        updateEditorState: (state, action) => {
            state.editorState = action.payload;
        },
        updateIsExport: (state, action) => {
            state.isExport = action.payload
        },
        updateIsFileChoose: (state, action) => {
            state.isFileChoose = action.payload
        },
        chooseFile: (state, action) => {
            state.file = action.payload
        },
        addPage: (state, action) => {
            state.pages.push(action.payload);
        },
        updatePage: (state, action) => {
            const { pageId, editorState } = action.payload;
            const page = state.pages.find(p => p.pageId === pageId);
            if (page) {
                page.editorState = editorState;
            }
        },
        deletePage: (state, action) => {
            state.pages = state.pages.filter(page => page.pageId !== action.payload.pageId);
        },
        setActivePage: (state, action) => {
            const { pageId } = action.payload;
            state.pages = state.pages.map(page => ({
                ...page,
                isActive: page.pageId === pageId
            }));
        },
        loadSignatures(state, action) {
            state.signatures = [...action.payload];
        },
        addSignature(state, action) {
            state.signatures.push(action.payload);
        },
        updateSignature(state, action) {
            const { signatureIndex, signatureData } = action.payload;
            if (state.signatures[signatureIndex]) {
                state.signatures[signatureIndex] = { ...signatureData };
            }
        },
        updateIsSaving: (state, action) => {
            state.savingData = {
                ...action.payload
            }
        },
        updatePages(state, action) {
            state.pages = [...action.payload];
        },
        setIsReadOnly(state, action) {
            state.isReadOnly = action.payload;
        }
    },
})

export const {
    pushEditorInstanceId,
    insertEditorInstancesDelta,
    updateActiveEditor,
    setEditorRef,
    updateEditorState,
    setDocumentInformation,
    setUserInformation,
    setMouse,
    updateIsFileChoose,
    updateIsExport,
    chooseFile,
    fetchFileContent,
    addPage,
    updatePage,
    deletePage,
    setActivePage,
    loadSignatures,
    addSignature,
    updateSignature,
    updateIsSaving,
    updatePages,
    setIsReadOnly,

} = editorSlice.actions;

export default editorSlice.reducer