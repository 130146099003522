import { io } from "socket.io-client";

// const URL = "https://pad.workw.com/";
// const URL = 'http://192.168.18.98:3100'

//Added local Socket Server for Testing
// const URL = "http://localhost:3100";
const URL = "https://pad.workw.com";

const socket = io(URL);
// emitters


export const emitLoadData = (data) => {
  socket.emit("load-data", data);
  // console.log('Load-Data From Socket')
};

export const listenLoadData = (data, callback) => {
  socket.on("load-data", () => {
    callback();
  });
  // console.log('Load-Data From Socket')
};





// Emitters for Chat Implemented here
export const emitLiveComments = (data) => {
  socket.emit("comments", data);
};

//Emits mouse coordinates to the server.
export const emitMousePositions = (obj) => {
  socket.emit("mouse-positions", obj);
};

// //Emits mouse coordinates to the server.


export const emitRoomID = (roomID) => {
  socket.emit("room-id", roomID);
}
// // listeners
// // listeners
// export const ListenLoadData = (callback) => {
//   socket.on("load-data", () => {
//     callback();
//   });
// };
export const renderMouse = (setConnectedSocketID) => {
  socket.on("mouse-positions", (info) => {
    setConnectedSocketID(info);
  });
};
export const addTotalClients = (setNumberOfConnectedClients) => {
  socket.on("total-clients", (totalClients) => {
    setNumberOfConnectedClients(totalClients);
  });
};

export const connectedClients = (setConnectedSocketID) => {
  socket.on("connected-clients-ids", (connectedClientArr) => {
    // console.log(connectedClientArr);
    setConnectedSocketID(connectedClientArr);
  });
};

export const removeTotalClients = (setNumberOfConnectedClients) => {
  socket.on("disonnected-client", (totalClients) => {
    setNumberOfConnectedClients(totalClients);
  });
};


const updateScroll = () => {
  var element = document.getElementById("myChat");
  element.scrollTop = element.scrollHeight;
};
// //Listener for Chats
export const listenerLiveComments = (updateMessageList) => {
  socket.on("comments", (data) => {
    data.color = "#98b3e6";
    data.margin = "5px auto 5px 0px"; //this styling work should be done through id
    updateMessageList(data);
    updateScroll()
  });
};
// export const listenerLiveComments = (updateMessageList) => {
//   socket.on("comments", (data) => {
//     data.color = "#98b3e6";
//     data.margin = "5px auto 5px 0px"; //this styling work should be done through id
//     updateMessageList(data);
//   });
// };



export default socket;
