import classes from './UserAvatar.module.css';
import { useState } from "react";
import { Badge, Image } from 'antd';
import { useSelector } from 'react-redux';

export const UserAvatar = ({isMobile}) => {
  const user = useSelector((state) => state.editor.userInformation);
  const [modalOpen, setModalOpen] = useState(false);
  const documentInformation = useSelector((state) => state.editor.documentInformation);
  const userInformation = useSelector((state) => state.editor.userInformation);
  const right={
    right:documentInformation.rightType==2?"Collaborator":"Reader"
  }
  const role={
    role:documentInformation.createBy == userInformation.userId?"Creator":"Member"
  }

  const getInitials = () => {
    const names = user.name.split(" ");
    let initials = names[0].substring(0, 1).toUpperCase();
    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };
  const renderProfileImage = (isDetailModalOpen) => {
    if (user.image) {
      return (
        <Badge
          style={{
            left: isDetailModalOpen ? '50px' : '32px',
            bottom: isDetailModalOpen ? '0px' : '-2px',
            width: isDetailModalOpen ? '12px' : '10px',
            height: isDetailModalOpen ? '12px' : '10px',
            top: 'auto',
            background: 'green',
            margin: '0 auto',
            display: 'block',
          }}
          dot={true}
        >
          <Image
            src={user.image}
            width={isMobile ? '40px' : "50px"}
            height={isMobile ? '40px' : "50px"}
            style={{ borderRadius: "50%" }}
            preview={isDetailModalOpen ? isDetailModalOpen : false}
          />
        </Badge>
      );
    } else {
      return (
        <Badge
          style={{
            left: isDetailModalOpen ? '40px' : '28px',
            bottom: isDetailModalOpen ? '0px' : '-1px',
            width: isDetailModalOpen ? '12px' : '10px',
            height: isDetailModalOpen ? '12px' : '10px',
            top: 'auto',
            background: 'green',
            margin: '0 auto',
            display: 'block',
          }}
          dot={true}
        >
          <div className={classes["no-profileImage-div"]}
            style={{
              width: isDetailModalOpen ? '80px' : '50px',
              height: isDetailModalOpen ? '80px' : '50px',
              fontSize: isDetailModalOpen ? '32px' : '22px'
            }}
          >
            {getInitials()}
          </div>
        </Badge>
      );
    }
  };
  return (
    <div
      onMouseLeave={() => setModalOpen(false)}
      onMouseEnter={() => setModalOpen(true)}
    >
      {renderProfileImage(false)}
      {modalOpen && (
        <div className={classes['custom-modal-container']}>
          <div className={classes['modal-profile-image']}>
            {renderProfileImage(true)}
            {/* <Badge color="#f50" size='large' offset={[10, 10]} style={{top:'50px', right:'12px',}}/> */}
          </div>
          {/* <span style={{fontWeight:'600', fontSize:'26px'}}>{user.name}</span>
          <span style={{fontWeight:'600', fontSize:'16px', marginTop:'-10px'}}>{user.designation}</span>
          <span style={{marginTop:'2px', color:'blue'}}className={classes['user-detail-text']}>{user.email}</span> */}
          <div className={classes['user-detail-container']}>
            <div>
              <span className={classes['user-detail-label']}>Name: </span>
              <span className={classes['user-detail-text']}>{user.name}</span>
            </div>
            <div>
              <hr />
              <span className={classes['user-detail-label']}>Email: </span>
              <span className={classes['user-detail-text']}>{user.email}</span>
            </div>
            <div>
              <hr />
              <span className={classes['user-detail-label']}>Rights: </span>
              <span className={classes['user-detail-text']}>{right.right}</span>
            </div>
            <div>
              <hr />
              <span className={classes['user-detail-label']}>Role: </span>
              <span className={classes['user-detail-text']}>{role.role}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
