import axios from "axios";
import { formatFileSize, getWorkWiseAuthToken } from "./utils/helper";
import { updateIsSaving } from "./app/EditorSlice";

// export const serverUrl = "http://localhost:3100/padApi/v2";
export const workwiseUrl = "https://workw.com/workwapi/api";
// export const workwiseUrl = "https://58.65.211.234:4436/konnectapi/api";  
export const serverUrl = "https://pad.workw.com/padApi/v2";
// export const serverUrl = "http://192.168.18.98:3100/padApi/v2";

const workwiseHeaders = { 'Authorization': `Bearer ${getWorkWiseAuthToken()}` }; // workwise auth header with bearer token
let debounceTimeout;

export const padServices = {

  async saveToDatabase(milepadID, milepadData = null, summary = null, dispatch) {
    try {
      const res = await axios.post(`${serverUrl}/update`, {
        milepadID,
        milepadData,
        summary
      });
      workwiseServices.saveDocumentlastUpdate(milepadID, dispatch, res.data);
      return res;
    } catch (e) {

    }
  },

  async fetchById(presentationId) {
    return axios.get(`${serverUrl}/getById/${presentationId}`);
  },
  async addSignature(signatureData, dispatch) {
    try {
      const response = await axios.post(`${serverUrl}/signature`, {
        userId: signatureData.id,
        documentId: signatureData.documentId,
        userName: signatureData.name,
        userDesignation: signatureData.designation,
        pageIndex: signatureData.pageIndex,
        signatureBoxStyle: signatureData.style
      },
        { headers: { 'workwiseAuthorization': `Bearer ${getWorkWiseAuthToken()}` } }
      );
      workwiseServices.saveDocumentlastUpdate(signatureData.documentId, dispatch, response.data);
      return response.data;
    } catch (error) {
      console.error('okkkError adding signature:', error);
      throw error;
    }
  },
  async updateSignature(signatureData, dispatch) {
    try {
      const response = await axios.patch(
        `${serverUrl}/signature/style/${signatureData.signatureId}`,
        {
          documentId: signatureData.documentId,
          signatureBoxStyle: signatureData.signatureBoxStyle
        }
        // { headers: workwiseHeaders }
      );
      workwiseServices.saveDocumentlastUpdate(signatureData.documentId, dispatch, response.data);
      return response.data;
    } catch (error) {
      console.error('Error updating signature:', error);
      throw error;
    }
  },
  async signDocumnet(signatureData) {
    const { signatureId, documentId } = signatureData;
    try {
      const response = await axios.patch(`${serverUrl}/signature/signDocument/${signatureId}`, {
        documentId,
      }, {
        headers: { 'workwiseAuthorization': `Bearer ${getWorkWiseAuthToken()}` }
      });
      return response;
    }
    catch (error) {
      console.error('Error signig Document:', error);
      throw error;
    }
  }
};


export const workwiseServices = {

  async getDocumentById(id) {
    // Todo : To be Used When Live is Down

    // const dummyResponse = {
    //   status: 200,
    //   data: {
    //     data: {
    //       rightType: 2,
    //       members: [],
    //       name: "Dummy",
    //       creator: {
    //         id: "dss",
    //         name: "YUP",
    //         image: "tt"
    //       },
    //       createBy: "dsds"
    //     }
    //   }
    // }
    // return dummyResponse;
    return axios.get(
      `${workwiseUrl}/Authorize/Authorize?id=${id}`,
      // {},
      { headers: workwiseHeaders });
  },
  async getAllSuggestion(payload) {
    return axios.post(
      `${workwiseUrl}/Suggestion/GetAllSuggestion`,
      payload,
      { headers: workwiseHeaders });
  },
  async addDocumentMember(id, payload) {
    return axios.post(
      `${workwiseUrl}/Document/AddDocumentMember?id=${id}`,
      payload,
      { headers: workwiseHeaders });
  },
  async shareDocumentToAnyone(payload) {
    return axios.post(`${workwiseUrl}/Document/UpdateDocument`,
      payload,
      { headers: workwiseHeaders }
    )
  },
  async saveDocumentlastUpdate(id, dispatch, data) {
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleString();
    const size = new TextEncoder().encode(JSON.stringify(data)).length || 0;
    const sizeWithUnit = formatFileSize(size);

    dispatch(updateIsSaving({ isSaving: 'Saved', lastSavedDateTime: formattedDate, fileSize: sizeWithUnit }));

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    debounceTimeout = setTimeout(async () => {
      try {
        await axios.post(`${workwiseUrl}/Document/SaveDocumentAttachment?attachmentId=${id}&fileSize=${size}`,
          {}, { headers: workwiseHeaders }
        );
        // Handle the response if needed
      } catch (e) {
        console.error("Error saving document attachment:", e);
      }
    }, 5000);
  },
  async completeDocument(id) {
    try {
      const formData = new FormData();
      formData.append('id', id);

      await axios.post(`${workwiseUrl}/Document/CompleteDocument?id=${id}`,
        formData,
        { headers: workwiseHeaders }
      );
      // Handle the response if needed
    } catch (e) {
      console.error("error", e);
    }
  }

};

