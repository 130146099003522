import { useState, useEffect } from "react";


const useRandomColor = () => {
  const [randomColor, setRandomColor] = useState("");
  const generateRandomColor = () => {
    const clr = Math.floor(Math.random() * 16777215).toString(16);
    setRandomColor(clr);
  };

  useEffect(() => {
    generateRandomColor();
  }, []);

  return randomColor;
};

export default useRandomColor;
