import { useState } from "react";
import MyPadName from "./PadName.module.css";
import cloud from "../../assets/Group 6244.svg";
import idk from "../../assets/Group 6253.svg";
import fav from "../../assets/Icon feather-star.svg";
import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";



const SheetName = ({isMobile}) => {
  const [gridTitle, setGridTitle] = useState("Untitled Milepad");
  const documentInformation = useSelector((state)=>state.editor.documentInformation);
  const [searchParams] = useSearchParams()
  // const documentName = searchParams.get("name");
  const documentName = documentInformation.name;
  // const docTitle = useSelector((state) => state.editor.docTitle);
  
  return (
    <div className={MyPadName['title-container']} style={{display:'flex'}}>
      <h1 className={MyPadName['title']}
        style={{

          fontSize: isMobile?15:22,
          // fontFamily: "Montserrat",
          // fontWeight: "400",
          marginRight: 10,
          width: 'max-content',
          maxWidth: '350px',
          overflow: "hidden",
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}>{documentName || "New Document"}</h1>
      {/* <div>
        <img src={fav} style={{ marginRight: 10,marginTop:12 }} />
        <img src={idk} style={{ marginRight: 10,marginTop:12 }} />
        <img src={cloud} style={{ marginRight: 10,marginTop:12 }} />
      </div> */}

    </div>
  );
};

export default SheetName;
