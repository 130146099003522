import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import EditorSlice from './EditorSlice';

export const store = configureStore({
  reducer: {
    editor: EditorSlice,
  },
  middleware: (getDefaultMiddleware) =>
getDefaultMiddleware({
  serializableCheck: false,
}),
});
