import React from "react";
import { GiArrowCursor } from "react-icons/gi";
// import Avatar from "../../assets/avatar.jpg";
import "./styles.css";
import useRandomColor from "../../hooks/useRandomColor";

const Mouse = ({ serverX, serverY, index,name }) => {
  const color = useRandomColor();
  const scrrenWidth=window.screen.width;
  let userDetailsX = serverX - 40;
  let userDetailsY = serverY - 43;
  
  return (
    <>{
    <div>
      <GiArrowCursor
        style={{
          color: color,
          position: "absolute",
          display:serverX<scrrenWidth?"block":"none",
          left: serverX-50,
          top: serverY-40,
        }}
      />
      <div
        style={{
          // backgroundColor:`#${color}`,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width:'fit-content',
          height: "50px",
          position: "absolute",
          color: color,
          borderRadius: "3px",
          left: userDetailsX,
          top: userDetailsY,
          display:serverX<scrrenWidth?"block":"none",
        }}
      >
        {/* <img src={Avatar} className="user-img" alt="user" /> */}
        <h6 style={{ color: `#${color}` }} className="user-name">{name||`User 0${index + 1}`}</h6>
      </div>
      </div>}
    </>
  );
};

export default Mouse;
