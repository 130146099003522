import React, { useState, useEffect } from "react";
import Mouse from "../mouse";
import useMousePosition from "../../hooks/useMousePosition";
import socket, {
  addTotalClients,
  connectedClients,
  emitMousePositions,
  removeTotalClients,
  renderMouse,
  emitRoomID
} from '../../Socket';
import { debounce } from "lodash";
import { useParams } from 'react-router-dom';
import { useRef } from "react";
import { getWorkWiseAuthToken } from "../../utils/helper";
import jwtDecode from "jwt-decode";

const MouseContainer = () => {

  const { id } = useParams();
  const [mousePositions, setMousePositions] = useState({
    serverX: null,
    serverY: null,
  });
  const [clientSocketID, setClientSocketID] = useState(null);
  const [connectedSocketID, setConnectedSocketID] = useState([]);
  const [user, setUser] = useState({});
  const { x, y, userName } = useMousePosition();

  const debouncedMousePosition = useRef(debounce((x, y, user) => {
    emitRoomID(id)
    setClientSocketID(socket.id);
    emitMousePositions({ id, clientSocketID, x, y, userName: user.name });
  }, 250)).current;

  useEffect(() => {
    debouncedMousePosition(x, y, user);
  }, [x, y]);

  useEffect(() => {
    setUser(jwtDecode(getWorkWiseAuthToken()));
  }, []);

  useEffect(() => {
    renderMouse(setConnectedSocketID);
    // connectedClients(setConnectedSocketID);
  }, []);

  return (
    <>
      {connectedSocketID.map((user, index) => {
        return (
          clientSocketID !== user.clientSocketID &&
          (
            <Mouse serverX={user.x} serverY={user.y} index={index} name={user.userName} />
          )
        );
      })}
    </>
  )
};

export default MouseContainer;
