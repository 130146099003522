import React from 'react';
import { Skeleton} from 'antd';
import MyHeader from '../Header/Header.module.css';
import SkeletonButton from 'antd/lib/skeleton/Button';
import SkeletonImage from 'antd/lib/skeleton/Image';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './Loader.css';

const Loader = () => {
    return (
        <>
            <div className='App'>
                <div className={MyHeader.headerMain} style={{ marginBottom: 10, backgroundColor: "#F4F4F4" }}>
                    <SkeletonButton active style={{ width: '100%', height: '100%' }} ></SkeletonButton>
                    <div style={{ backgroundColor: "#F4F4F4", width: 40, height: 40, borderRadius: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <SkeletonImage active style={{ width: '40px', height: '30px' }} />
                    </div>
                    <div className={MyHeader.headerCol}>
                    </div>
                    <div className={MyHeader.titleBarButtons}>
                        <div style={{ position: "absolute", right: "10px", top: "26px", cursor: "pointer", zIndex: 1 }} >
                            <Skeleton.Image active style={{ height: "0px", marginLeft: '-5px', }}></Skeleton.Image>
                            <Skeleton.Image active style={{ width: '28px', height: "0px", marginLeft: '35px' }}></Skeleton.Image>
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex", marginTop: "45px", display: "flex",paddingLeft: 80,}}>
                    <div active style={{ width: '350px', backgroundColor: "white", marginTop: 70, marginRight: 40 }}>
                        <Skeleton.Button active style={{ width: '300px', height: '80px', marginTop: '40px' }}></Skeleton.Button>
                        <hr />
                        <Skeleton active style={{ alignSelf: 'center' }} />
                    </div>
                    <div className='EditorContainer'>
                        <SkeletonButton active className='Loader-editor-container' style={{ width: '100%', height: "100%" }}></SkeletonButton>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Loader;
