import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getWorkWiseAuthToken } from "./utils/helper";
import { workwiseServices } from "./api";
import { setDocumentInformation, setUserInformation } from '../src/app/EditorSlice';
import { useDispatch } from "react-redux";
import Loader from "./components/SkeletonLoader/Loader";
import jwtDecode from "jwt-decode";

export const AuthenticateDocument = ({ setIsLoading }) => {

  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

  const handleAuhenticate = async () => {
    try {
      setIsLoading(true);
      if (!window.navigator.onLine) {
        // navigate('/no-internet');
        window.location.pathname="/no-internet"
        return;
      }
      else{
        if (!!getWorkWiseAuthToken()) {
          const response = await workwiseServices.getDocumentById(id);
          if (response?.status==200 && response.data?.data?.rightType !== 0 && response.data?.data) {
            setIsLoading(false);
            dispatch(setDocumentInformation(response.data.data));
          }
          if(response.data?.data?.rightType == 0)
          {
            // navigate("/unauthorized")
            window.location.pathname="/unauthorized"
          }
          // history.push("/unauthorized")
        }
        else {
          // navigate("/unauthorized")
          window.location.pathname="/unauthorized"
        }
      }
    } catch (error) {
      console.log({ error })
      if (error.response?.data?.responseCode === 1008) {
        // navigate("/invalid-document")
        window.location.pathname="/invalid-document"

      }
      else
      {
        // navigate("/unauthorized")
        window.location.pathname="/unauthorized"

      }
    }
  }

  useEffect(() => {
    handleAuhenticate()
    dispatch(setUserInformation(jwtDecode(getWorkWiseAuthToken())));
  }, []);

  return <Loader/>

}