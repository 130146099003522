import React from 'react';

const WorkWsieIcon = ({ width = "300", height = "180"  , logoOnly=false}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 203.195 127.372">
      <g id="workwise_logo_white" data-name="workwise logo white" transform="translate(2352.723 375.102)">
        <g id="Layer_1-2" data-name="Layer 1-2" transform={!logoOnly?"translate(-2352.722 -375.102)":"translate(-2352.722 -355.102)"}>
          <rect id="Rectangle_2359" data-name="Rectangle 2359" width="57.429" height="11.9" rx="5.95" transform="translate(45.287 51.299) rotate(-60.113)" fill="#64c4b2" />
          <rect id="Rectangle_2360" data-name="Rectangle 2360" width="64.006" height="11.859" rx="5.93" transform="translate(79.555 76.68) rotate(-61.2)" fill="#526bb1" />
          <rect id="Rectangle_2361" data-name="Rectangle 2361" width="42.345" height="11.859" rx="5.93" transform="translate(102.766 77.235) rotate(-61.2)" fill="#e8137b" />
          <rect id="Rectangle_2362" data-name="Rectangle 2362" width="41.996" height="11.859" rx="5.93" transform="translate(126.781 76.685) rotate(-60)" fill="#f7d447" />
          <rect id="Rectangle_2363" data-name="Rectangle 2363" width="88.648" height="11.9" rx="5.95" transform="translate(55.177 76.859) rotate(-60.113)" fill="#45c6ee" />
          {
            !logoOnly ? <>
              <path id="Path_4830" data-name="Path 4830" d="M-2316.13,239.448l-7.782,23.5h-4.633l-5.812-17.388-5.91,17.388h-4.665l-7.789-23.5h4.532l5.841,17.859,6.075-17.859h4.028l5.942,17.96,6.01-17.96Z" transform="translate(2352.722 -135.91)" fill="#fff" />
              <path id="Path_4831" data-name="Path 4831" d="M-2119.6,260.068a11.569,11.569,0,0,1-4.532-4.332,12.485,12.485,0,0,1,0-12.386,11.554,11.554,0,0,1,4.532-4.33,13.4,13.4,0,0,1,6.48-1.562,13.388,13.388,0,0,1,6.478,1.562,11.583,11.583,0,0,1,4.532,4.313,11.889,11.889,0,0,1,1.646,6.21,11.848,11.848,0,0,1-1.646,6.21,11.589,11.589,0,0,1-4.532,4.315,13.4,13.4,0,0,1-6.478,1.56A13.41,13.41,0,0,1-2119.6,260.068Zm10.708-3.324a7.65,7.65,0,0,0,2.955-2.953,8.938,8.938,0,0,0,0-8.493,7.654,7.654,0,0,0-2.955-2.955,8.983,8.983,0,0,0-8.459,0,7.644,7.644,0,0,0-2.953,2.955,8.929,8.929,0,0,0,0,8.493,7.64,7.64,0,0,0,2.96,2.953,8.983,8.983,0,0,0,8.459,0Z" transform="translate(2164.008 -134.256)" fill="#fff" />
              <path id="Path_4832" data-name="Path 4832" d="M-1932.667,262.947l-4.8-6.882a8.624,8.624,0,0,1-.906.034h-5.3v6.849h-4.365v-23.5h9.669a12.974,12.974,0,0,1,5.321,1.011,7.753,7.753,0,0,1,3.474,2.888,8.081,8.081,0,0,1,1.208,4.465,7.957,7.957,0,0,1-1.292,4.564,7.7,7.7,0,0,1-3.707,2.854l5.4,7.721Zm-1.577-18.6a6.709,6.709,0,0,0-4.332-1.208h-5.1v9.366h5.1a6.643,6.643,0,0,0,4.332-1.225,4.255,4.255,0,0,0,1.476-3.474,4.217,4.217,0,0,0-1.476-3.459Z" transform="translate(2016.221 -135.91)" fill="#fff" />
              <path id="Path_4833" data-name="Path 4833" d="M-1794.3,253.111l-3.725,3.826v6.01h-4.365v-23.5h4.365V251.5l11.615-12.051h4.9l-9.869,10.516,10.473,12.991h-5.1Z" transform="translate(1895.11 -135.91)" fill="#fff" />
              <path id="Path_4834" data-name="Path 4834" d="M-1641.862,239.448l-7.787,23.5h-4.633l-5.807-17.388L-1666,262.947h-4.667l-7.787-23.5h4.532l5.841,17.859,6.075-17.859h4.028l5.942,17.96,6.01-17.96Z" transform="translate(1792.051 -135.91)" fill="#fff" />
              <path id="Path_4835" data-name="Path 4835" d="M-1440.152,239.448h4.365v23.5h-4.365Z" transform="translate(1593.899 -135.91)" fill="#fff" />
              <path id="Path_4836" data-name="Path 4836" d="M-1384.331,260.873a11.057,11.057,0,0,1-4.011-2.049l1.516-3.39a11.9,11.9,0,0,0,3.538,1.863,12.858,12.858,0,0,0,4.212.721,7.256,7.256,0,0,0,3.91-.842,2.547,2.547,0,0,0,1.292-2.215,2.149,2.149,0,0,0-.721-1.661,5.08,5.08,0,0,0-1.83-1.024q-1.107-.369-3.021-.842a35.255,35.255,0,0,1-4.348-1.275,7.142,7.142,0,0,1-2.852-2,5.377,5.377,0,0,1-1.193-3.676,6.277,6.277,0,0,1,1.058-3.538,7.118,7.118,0,0,1,3.189-2.527,13.114,13.114,0,0,1,5.223-.94,16.84,16.84,0,0,1,4.23.537,11.706,11.706,0,0,1,3.592,1.543l-1.376,3.391a13.569,13.569,0,0,0-3.223-1.376,12.056,12.056,0,0,0-3.257-.47,6.8,6.8,0,0,0-3.843.873,2.7,2.7,0,0,0-1.264,2.306,2.091,2.091,0,0,0,.721,1.644,5.2,5.2,0,0,0,1.83,1.011q1.107.369,3.021.842a31.826,31.826,0,0,1,4.3,1.259,7.359,7.359,0,0,1,2.864,2,5.273,5.273,0,0,1,1.193,3.626,6.184,6.184,0,0,1-1.058,3.525,7.192,7.192,0,0,1-3.2,2.517,13.023,13.023,0,0,1-5.236.94A17.939,17.939,0,0,1-1384.331,260.873Z" transform="translate(1550.818 -134.272)" fill="#fff" />
              <path id="Path_4837" data-name="Path 4837" d="M-1233.638,259.288v3.659h-17.624v-23.5h17.154v3.659H-1246.9v6.109h11.347v3.592H-1246.9v6.48Z" transform="translate(1436.833 -135.91)" fill="#fff" />
            </> : <></>
          }

        </g>
      </g>
    </svg>
  )
}

export default WorkWsieIcon;