import React, { useState } from 'react';
import { Modal } from 'antd';
import signatureIcon from '../../../../assets/CustomButton-Icons/signature-Icon.png'
import SignatureModalContent from '../InsertSignatureUsersModal/SignatureModalContent/signatureModalContent';
import { useSelector } from 'react-redux';

const LexicalSignatureModal = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const documentInformation = useSelector((state) => state.editor.documentInformation);

    const showModal = (e) => {
        e.preventDefault();
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    return (
        documentInformation.rightType === 2 && (
            <>
                <div title="Signature" onClick={showModal} style={{ marginTop: "4px",marginLeft:"4px" }}>
                    <img src={signatureIcon} width={20} alt="Signature Icon" />
                </div>
                <Modal
                    title="Document Signature"
                    visible={isModalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    footer={null}
                    zIndex={9999}
                    style={{ height: 'max-content' }}
                >
                    <SignatureModalContent setIsModalVisible={setIsModalVisible} />
                </Modal>
            </>
        )
    );
}

export default LexicalSignatureModal;