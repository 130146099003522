import React, { useState, useRef } from 'react';
import { Popconfirm, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { debounce } from "lodash";
import { loadSignatures, updateIsSaving, updateSignature } from '../../../app/EditorSlice';
import { padServices } from '../../../api';
import './signatures.css';

const LexicalSignatures = ({ pageIndex }) => {

    const dispatch = useDispatch();
    const { id } = useParams();
    const isReadOnly = useSelector((state) => state.editor.isReadOnly);
    const userInformation = useSelector((state) => state.editor.userInformation);
    let pages = useSelector((state) => state.editor.pages);
    const signatures = useSelector((state) => state.editor.signatures);
    const [signatureAdditionalKeys, setSignatureAdditionalKeys] = useState({});

    const makeDraggable = (e, pageIndex, signatureId) => {

        let signatureContainer = document.getElementById(signatureId);
        let pageContainer = document.getElementById('lexical-editor');
        let container;
        if (!signatureContainer || !pageContainer) {
            console.warn('okkkSignature container or page container not found');
            return;
        }
        if (isReadOnly) {
            signatureContainer.style.cursor = 'default';
            return;
        }

        e.stopPropagation();
        e.preventDefault();
        container = pageContainer;
        let isDragging = false;
        let originalX, originalY, mouseX, mouseY, maxLeft, maxTop;
        signatureContainer.style.cursor = 'move';

        const onMouseDown = (e) => {
            isDragging = true;
            originalX = signatureContainer.offsetLeft;
            originalY = signatureContainer.offsetTop;
            mouseX = e.clientX;
            mouseY = e.clientY;
            e.preventDefault();

            maxLeft = container.offsetWidth - signatureContainer.offsetWidth;
            maxTop = container.offsetHeight - signatureContainer.offsetHeight;

            document.addEventListener('mousemove', onMouseMove);
            document.addEventListener('mouseup', onMouseUp);
            signatureContainer.ondragstart = () => false;
        };

        const onMouseMove = (e) => {
            e.preventDefault();
            e.stopPropagation();
            if (!isDragging) return;
            let deltaX = e.clientX - mouseX;
            let deltaY = e.clientY - mouseY;
            let maxTop = pageContainer.clientHeight - 40;
            let signatureImgContainer = signatureContainer.getElementsByTagName('IMG');
            if (Array.from(signatureImgContainer).length > 0) {
                maxTop = pageContainer.clientHeight - 100;
            }
            let newLeft = Math.min(Math.max(0, originalX + deltaX), 595);
            let newTop = Math.min(Math.max(0, originalY + deltaY), maxTop);
            signatureContainer.style.left = newLeft + 'px';
            signatureContainer.style.top = newTop + 'px';
        };

        const onMouseUp = () => {
            if (!isDragging) return;
            isDragging = false;
            // signatureContainer.style.cursor = 'pointer';
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
            const allSignatures = signatures;
            let respectiveBoxIndex = allSignatures.findIndex((signatureBox) => signatureBox._id === signatureContainer.id);
            if (respectiveBoxIndex >= 0) {
                const computedStyle = window.getComputedStyle(signatureContainer);
                const updatedStyle = {
                    top: computedStyle.getPropertyValue('top'),
                    left: computedStyle.getPropertyValue('left')
                }
                let payload = {
                    signatureIndex: respectiveBoxIndex,
                    signatureData: {
                        ...allSignatures[respectiveBoxIndex],
                        signatureBoxStyle: updatedStyle
                    },
                }
                updateSignatureDebounce(payload, allSignatures, updatedStyle);
            }
        };
        signatureContainer.addEventListener('mousedown', onMouseDown);
    };

    const updateSignatureDebounce = useRef(debounce((payload, signatures, updatedStyle) => {
        dispatch(updateIsSaving({ isSaving: 'Saving...', lastSavedDateTime: '' }));
        let signatureData = signatures[payload.signatureIndex] || null;
        if (signatureData) {
            const prevStyle = signatureData.signatureBoxStyle || null;
            if (prevStyle && prevStyle.top !== updatedStyle.top && prevStyle.left !== updatedStyle.left) {
                padServices.updateSignature({
                    signatureId: signatureData._id,
                    documentId: id,
                    signatureBoxStyle: updatedStyle
                }, dispatch);
                dispatch(updateSignature(payload));
            }
        }
    }, 400)).current;

    const handleConfirm = async (e, signature) => {
        try {
            const res = await padServices.signDocumnet({ documentId: id, signatureId: signature._id });
            dispatch(loadSignatures(res?.data?.data?.Signatures || []));
            message.success('Document signed successfully');
            const updatedStyle = adjustSignatureBoxPosition(signature);
            if (updatedStyle) {
                await padServices.updateSignature({
                    signatureId: signature._id,
                    documentId: id,
                    signatureBoxStyle: updatedStyle
                });
            }
        }
        catch (e) {
            const errorMessage = e.response.data.message || 'Error while Signing the document';
            message.error(errorMessage);
        }
        setSignatureAdditionalKeys(prevState => ({
            ...prevState,
            [signature._id]: false
        }));
    };

    const checkImageAndConfirm = (e, signature) => {
        e.stopPropagation();
        e.preventDefault();
        let signatureContainer = document.getElementById(signature._id) || null;
        if (signatureContainer) {
            let signatureImgContainer = signatureContainer.getElementsByTagName('IMG');
            if (signature.userId === userInformation.userId && Array.from(signatureImgContainer).length === 0) {
                setSignatureAdditionalKeys(prevState => ({
                    ...prevState,
                    [signature._id]: true
                }));
            }
        }
    };

    const handleClose = (signatureId) => {
        setSignatureAdditionalKeys(prevState => ({
            ...prevState,
            [signatureId]: false
        }));
    };

    const handleVisibleChange = (newVisible, signatureId) => {
        setSignatureAdditionalKeys(prevState => ({
            ...prevState,
            [signatureId]: newVisible
        }));
    };

    const handlePlaceSignature = (e, signature) => {
        checkImageAndConfirm(e, signature);
    }

    const adjustSignatureBoxPosition = (signature) => {
        let pageContainer = document.getElementById('lexical-editor');
        if (pageContainer) {
            const pageHeight = pageContainer.clientHeight;
            let signatureContainer = document.getElementById(signature._id);
            if (signatureContainer) {
                const signatureBoxTop = parseInt(signature.signatureBoxStyle.top);
                if (signatureBoxTop >= pageHeight - 40) {
                    let updatedTop = pageHeight - 100;
                    let updatedStyle = {
                        top: `${updatedTop}px`,
                        left: signature.signatureBoxStyle.left
                    };
                    signatureContainer.style.top = `${updatedTop}px`;
                    return updatedStyle;
                }
            }
        }

        return null;
    };

    return (
        <>
            {signatures.map((signature) => {
                const signatureId = signature._id;
                const style = signature.signatureBoxStyle || null;
                const imageUrl = signature.signatureUrl || '';
                const signedDate = signature.isSigned && signature.signedAt ? new Date(signature.signedAt) : null;
                const date = signedDate ? signedDate.toLocaleDateString() : '';
                const time = signedDate ? signedDate.toLocaleTimeString() : '';
                const boxTop = parseInt(style.top, 10);
                let pageHeight = document.getElementById('lexical-editor').clientHeight;
                const isAddSigBtn =  userInformation.userId === signature.userId && !signature.isSigned && isReadOnly && boxTop >= pageHeight - 40;
                // if (signature.pageIndex === pageIndex) {
                return (
                    <div
                        key={signatureId}
                        id={signatureId}
                        className='editor-signature-container'
                        style={{
                            top: isAddSigBtn ? `${boxTop - 30}px` : style?.top,
                            left: style?.left || 595,
                            width: 'auto',
                            height: 'auto'
                        }}
                        // onClick={(e) => handleSignatureClick(e, signature)}
                        onMouseEnter={(e) => makeDraggable(e, pageIndex, signatureId, signature.isSigned)}
                    // onDoubleClick={(e) => checkImageAndConfirm(e, signature)}
                    >
                        <div id={signatureId} className='editor-signature-box' style={{ borderBottom: !signature.isSigned ? '1px solid black' : 'none' }}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                {signature.isSigned ? (
                                    <img src={imageUrl} className='editor-signature-image' />
                                ) : (
                                    userInformation.userId === signature.userId && isReadOnly &&
                                    <span
                                        className="placeSig-btn"
                                        onClick={(e) => handlePlaceSignature(e, signature)}
                                    >
                                        Place Signature
                                    </span>
                                )}
                            </div>
                            {signature.isSigned &&
                                <div className='date-time-style'>
                                    <span>{date} | {time}</span>
                                </div>
                            }
                        </div>
                        {signature.isSigned &&
                            <div className="custom-border">
                                <div className="border-part"></div>
                                <div className="signature-id">{signatureId}</div>
                                <div className="border-part"></div>
                            </div>
                        }
                        <div id={signatureId} className='editor-user-detail'>
                            <span>{signature.userName}</span>
                            <span className='designation-style'>{signature.userDesignation}</span>
                        </div>
                        <Popconfirm
                            title="Are you sure you want to sign this document?"
                            visible={!!signatureAdditionalKeys[signature._id]}
                            onConfirm={(e) => handleConfirm(e, signature)}
                            onCancel={() => handleClose(signature._id)}
                            okText="Yes"
                            cancelText="No"
                            onVisibleChange={(newVisible) => handleVisibleChange(newVisible, signature._id)}
                        />
                    </div>
                );
                // }
                return null;
            })}
        </>
    )
}

export default LexicalSignatures;