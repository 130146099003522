import React, { useState } from 'react'
// import {} from 'antd'
import {ReactComponent as ModesIcon} from '../../assets/modes.svg';
import {ReactComponent as DarkIcon} from '../../assets/Dark-Mode.svg';

import {
    enable as enableDarkMode,
    disable as disableDarkMode,
} from 'darkreader';




function Modes({isMobile}) {

    const [darkMode,setDarkMode]=useState(true)
    // Element got to apply styles on specific Modes
    let toolBars = document.getElementsByClassName('rdw-option-wrapper'); // Corrected class name
    // console.log('darkMode_', darkMode_);
    // console.log("Toolbar", toolBars);
    let toolBarsArray = Array.from(toolBars);
    
    const setMode=()=>{

        // console.log('darkMode : ',darkMode)
        // alert('Dark Mode')
        if(darkMode){
            enableDarkMode({
                brightness: 100,
                contrast: 90,
                sepia: 10,
            });
          
              // Convert HTMLCollection to an array
            
              toolBarsArray.forEach((tool) => {
                tool.style.backgroundColor = 'rgba(126, 126, 126, 0)';
                tool.style.filter = 'invert(65%) ';
              });
            
          }else{
            disableDarkMode();
            toolBarsArray.forEach((tool) => {
              tool.style.backgroundColor = 'rgba(126, 126, 126, 0)';
              tool.style.filter = 'none';
            });

        }
        setDarkMode(!darkMode)
    }
  return (
    <div>
            <div onClick={setMode} style={{ height:40, position: "relative", marginRight: "2px", top:isMobile?"10px": "5px", cursor: "pointer", zIndex: 1,opacity:.95 ,}} >
              {darkMode==!true ?
              <ModesIcon style={{width:isMobile?"25px":"32px",height:isMobile?"25px": "32px" }}/>:
              <DarkIcon style={{width:isMobile?"25px": "32px",height:isMobile?"25px": "32px" }}/>}
            </div>
    </div>
  )
}

export default Modes