import React, { useEffect } from "react";
import WorkWsieIcon from "./WorkWsieIcon";
import { useMediaQuery } from "react-responsive";
function ResultComponent({ title }) {

  const isGalaxyFolded = useMediaQuery({ width: 280, height: 653 });

  return (
    
    <div style={styles.container} >
      {
        isGalaxyFolded ?
          <WorkWsieIcon height="140" width="150" /> :
          <WorkWsieIcon />
      }
      <a style={isGalaxyFolded ? styles.smallText : styles.textAnchor} href="https://www.workw.com">
        {"www.workw.com"}
      </a>
      <div style={isGalaxyFolded ? styles.smallText : styles.text}>
        {title}
      </div>
      <div style={styles.coprightContainer}>
        Workwise - Copyrights @2016-2023
      </div>
    </div>
  );
}


const styles = {
  container: {
    backgroundColor: "black",
    width: "100vw",
    height:"100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  smallText: {
    marginTop: "0px",
    color: "white",
    fontSize: 15,
    textAlign: "center",
  },
  text: {
    marginTop: "20px",
    color: "white",
    fontSize: 25,
    textAlign: "center",
  },
  textAnchor: {
    textDecoration: "underline",
    // color: "blue",
    marginTop: "10px",
    // color: "white",
    fontSize: 20,
  },
  coprightContainer: {
    justifyContent:"center",
    alignItems:"center", 
    display:"flex", 
    columnGap:"8px", 
    bottom: "5px",
    // left: "10px",
    color: "white",
    fontSize: 13,
    position: "absolute",

  }
}
export default ResultComponent;