import { Avatar, Badge } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import './members.css';
import { renderMouse } from '../../Socket';

const DocumentMembers = () => {
    const document = useSelector((state) => state.editor.documentInformation);
    const user = useSelector((state) => state.editor.userInformation);
    const [onlineUsers, setOnlineUsers] = useState([]);
    const colorOptions = useMemo(() => ['#f56a00', '#7265e6', '#ffbf00', '#87d068', '#ff6b81', '#00a2ae'], []);
    const [memberColors, setMemberColors] = useState({});

    const getInitials = (name) => {
        const names = name.split(" ");
        let initials = names[0].substring(0, 1).toUpperCase();
        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    };

    useEffect(() => {
        renderMouse(setOnlineUsers);
        // connectedClients(setConnectedSocketID);
    }, []);

    const getRandomColor = (memberId) => {
        if (memberColors[memberId]) {
            return memberColors[memberId];
        }
        let newColor;
        do {
            newColor = '#' + (Math.random() * 0xFFFFFF << 0).toString(16);
        } while (newColor === '#CF9FFF' || Object.values(memberColors).includes(newColor));
        setMemberColors((prevColors) => ({
            ...prevColors,
            [memberId]: newColor,
        }));
        return newColor;
    };

    return (
        <div style={{ marginRight: "6px" }}>
            <Avatar.Group
                maxCount={document?.members?.length}
                maxStyle={{
                    color: "#f56a00",
                    backgroundColor: "#fde3cf",
                }}
            >
                {document?.members?.length >= 1 && document.members.map((member, ind) => {
                    if (user.userId !== member.id) {
                        const backgroundColor = getRandomColor(member.id);
                        return (
                            <Badge
                                key={ind}
                                offset={["-6%", "95%"]}
                                style={{
                                    width: "10px",
                                    height: "10px",
                                    backgroundColor: "green"
                                }}
                                dot={onlineUsers.some(onlineUser => onlineUser.userName == member.name)}
                            >
                                <Avatar
                                    src={member.image}
                                    style={{
                                        backgroundColor: backgroundColor,
                                        marginLeft: '-5px',
                                        width: '42px',
                                        height: '42px',
                                        lineHeight: '42px',
                                        fontSize: '16px'
                                    }}
                                >
                                    {getInitials(member.name)}
                                </Avatar>
                            </Badge>
                        );
                    }
                })}
            </Avatar.Group>
        </div>
    );
}

export default DocumentMembers;
