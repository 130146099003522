import React from 'react';
import completeIcon from '../../assets/complete.png'; 
import { workwiseServices } from '../../api';
import { useDispatch, useSelector } from 'react-redux';
import { setIsReadOnly } from '../../app/EditorSlice';
import { message, Modal } from 'antd';

const { confirm } = Modal;

const CompleteDocument = () => {
    const dispatch = useDispatch();
    const { referenceId } = useSelector((state) => state.editor.documentInformation);

    const handleClick = async () => {
        try {
            await workwiseServices.completeDocument(referenceId);
            message.success('Document completed successfully.');
            dispatch(setIsReadOnly(true));
        } catch (error) {
            message.error('Failed to complete the document. Please try again.');
        }
    };

    const showConfirmationModal = (e) => {
        e.preventDefault();
        confirm({
            title: 'Are you sure you want to complete this document?',
            content: 'This action cannot be undone.',
            onOk() {
                handleClick();
            },
            onCancel() {
            },
            zIndex: 9999, 
            maskClosable:true,
        });
    };

    return (
        <div
            style={{
                marginTop: '10px',
                marginLeft: "8px",
                cursor: 'pointer'
            }}
            title='Complete Document'
            onClick={(e)=>showConfirmationModal(e)}
        >
            <img src={completeIcon} width={26} alt="Complete Document" />
        </div>
    );
};

export default CompleteDocument;