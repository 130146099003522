import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";

const hardcodeToken = null;

function getCookieValue(name) {
  const regex = new RegExp(`(^| )${name}=([^;]+)`)
  const match = document.cookie.match(regex)
  if (match) {
    return match[2]
  }
}

export function getWorkWiseAuthToken() {
  const searchParams = new URLSearchParams(document.location.search);
  let cookieToken = getCookieValue("token")
  let queryToken = searchParams.get("token");
  const finalToken = queryToken || cookieToken || hardcodeToken;
  return finalToken;
}

export const generateHtml = (contentState) => {
  let rawContentState = convertToRaw(contentState);
  rawContentState.blocks = rawContentState.blocks.map(block => {
    if (block.type.startsWith('header')) {
      if (!block.data.id) {
        block.data.id = `heading-${block.key}`;
      }
    }
    const newData = {
      ...block.data,
      ...(block.data.lineHeight ? { lineHeight: block.data.lineHeight } : {}),
      ...(block.data.textAlignment ? { 'text-align': block.data.textAlignment } : {}),
    };
    return {
      ...block,
      data: newData
    };
  });
  // Convert to HTML
  const htmlWithIDs = draftToHtml(rawContentState);
  return htmlWithIDs;
};

export const debounce = (func, wait) => {
  let timeout;
  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
};

export const formatFileSize = (bytes) => {
  if (bytes === 0) return "0";

  const units = ["Bytes", "KB", "MB", "GB"];
  let size = bytes;
  let unitIndex = 0;

  while (size >= 1024 && unitIndex < units.length - 1) {
    size /= 1024;
    unitIndex++;
  }

  if (unitIndex === 0) {
    return `${size} ${units[unitIndex]}`;
  } else if (unitIndex === 1 && size < 50) {
    return `${parseFloat(size.toFixed(0))} ${units[unitIndex]}`;
  } else {
    return `${parseFloat(size.toFixed(2))} ${units[unitIndex]}`;
  }
};
export const showPagebreak = () => {
  const pageContainer = document.getElementById('lexical-editor-container');
  const editorContainer = document.getElementById('lexical-pages-container');
  if (!pageContainer || !editorContainer) return;

  // Remove existing page break lines
  document.querySelectorAll('.page-break').forEach(breakElement => breakElement.remove());

  // initial height of one "page"
  const initialPageHeight = 1180 ;

  // actual content height
  const contentHeight = pageContainer.scrollHeight;

  // number of pages based on content height
  const numberOfPages = Math.floor(contentHeight / initialPageHeight);

  if (contentHeight > initialPageHeight) {
    for (let i = 1; i <= numberOfPages; i++) {
      const pageBreakLine = document.createElement('div');
      pageBreakLine.className = 'page-break';
      pageBreakLine.style.position = 'absolute';
      pageBreakLine.style.top = `${i * initialPageHeight}px`;
      pageBreakLine.style.width = '100%';
      pageBreakLine.style.height = '8px';
      pageBreakLine.style.pointerEvents = 'none';
      pageBreakLine.style.marginLeft = '-26px';
      pageBreakLine.style.borderTop = '2px dotted #000';
      pageBreakLine.style.background = 'white';
      editorContainer.appendChild(pageBreakLine);
    }
  }
};