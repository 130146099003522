import { Button, Modal, Input, message } from 'antd'
import React, { useState } from 'react'
import { generateAiText } from './generateAiText';
import ToneSelect from './toneSelect';





function AiTextButton({ isOpen, setIsOpenAi, showAiText, setAiModalVisibility, aiModalVisibility }) {
    const isMobile = window.screen.width < 768;
    const [tone, setTone] = useState("Formal");
    const [isLoading, setIsLoading] = useState(false);
    const [isFirstSubmit, setIsFirstSubmit] = useState(true);
    const [state, setState] = useState(({
        input: "",
        tone: 0
    }))
    const handleSubmit = async () => {
        setIsLoading(true)
        if (!state.input) {
            message.error('Please specify what you\'d like to generate.');
            setIsLoading(false)

            return;
        }
        else if (tone=="") {
            message.error('Please select Tone');
            setIsLoading(false)

            return;
        }

        const aiQuestion = `Write a paragraph covering the following points "${state.input}." in the ${tone} tone. Maximum in 200 words`
        const response = await generateAiText(aiQuestion);
        // const response={
        //     status:1,
        //     data:'This is DUmmy Text from Ai Sample'
        // }
        if (response.status) {
            setIsFirstSubmit(false)
            setIsOpenAi(false)
            showAiText(response.data)
            // simulateTypingEffect(response.data)
        } else {
            message.error(response.message)
        }

        setIsLoading(false)
        setAiModalVisibility(false)
        setState({
            ...state,
            input: '',
          });
    }
    const handleCancel = () => {
        setAiModalVisibility(false)
        setTone('Formal')
        setState({
            ...state,
            input: '',
          });
    }
    return (
        <div>
            <Modal
                title="Generate with AI"
                onCancel={handleCancel}
                visible={aiModalVisibility}
                footer={false}
                zIndex={9999}
            >
                <Input placeholder="What would you like to write"
                value={state.input}
                    onChange={(e) => setState({
                        ...state,
                        input: e.target.value
                    })}
                />

                <ToneSelect setTone={setTone} />              
                <Button loading={isLoading} htmlType="submit" style={{color:'#fff',backgroundColor:'#526bb1', width: 'max-content', marginTop: '10px', marginLeft:isMobile?'75%': '80%', borderRadius: '5px' }} onClick={handleSubmit}>
                    Generate
                </Button>

            </Modal>
        </div>
    )
}

export default AiTextButton